@import url(https://fonts.googleapis.com/css?family=Jura:600&display=swap);
* {
  font-family: "Jura", sans-serif;
  font-size: 12pt;
}
html {
  background-color: #2c2c2c;
}

.styles_overlay__CLSq- {
  z-index: 9999;
}
.styles_overlay__CLSq- div {
  padding: 0;
}
.pagination li {
  background-color: #0c0c0c;
  color: #dbdbdb;
  margin: 5px;
  padding: 7px;
  width: 40px;
  height: 40px;
  text-align: center;
  cursor: pointer;
  border-radius: 50%;
}
.pagination li.active {
  background-color: #dbdbdb;
  color: #0c0c0c;
}

div.container {
  padding: 0;
  margin: 0;
  max-width: none;
  font-family: "Jura", sans-serif;
  font-size: 12pt;
}

/* NAVBAR */

.navbar-dark .navbar-toggler {
  border: none;
}

.navbar-dark .navbar-toggler:focus {
  outline: none;
}

.navbar-dark .navbar-brand {
  text-align: center;
  margin-right: 0;
}

.navbar-expand-lg .navbar-nav {
  padding: 0;
}

.navbar {
  background-image: -webkit-gradient(
    linear,
    left bottom, left top,
    from(rgba(26, 26, 26, 0.623)),
    to(rgba(26, 26, 26, 0.623))
  );
  background-image: linear-gradient(
    to top,
    rgba(26, 26, 26, 0.623),
    rgba(26, 26, 26, 0.623)
  );
  color: #ffffff96;
  display: inline-grid;
  justify-content: center;
  box-shadow: 0px 4px 14px -2px rgba(0, 0, 0, 0.56);
}

nav.fixed-top {
  display: inline-grid;
  justify-content: center;
  padding-bottom: 0;
  padding-top: 0;
}
.navbar:hover {
  background-color: #0c0c0c;
}
.collapse ul.navbar-nav {
  display: -webkit-inline-box;
  padding-right: 40px;
  padding: 10px 10px 0 10px;
}

.product-navbar {
  padding-bottom: 10px;
}

.navbar-collapse {
  padding: 0 10px 0 10px;
}

li.lang-btn {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
}

/* PRODUCTS PAGE NAVBAR */

#products-list {
  position: absolute;
  left: 0;
  margin-top: 10px;
  background-color: #2b2b2b;
  color: rgba(255, 255, 255, 0.5);
  list-style: none;
  padding: 0;
  width: 100%;
  padding: 10px;
  box-shadow: 0px 4px 16px 6px rgba(0, 0, 0, 0.56);
}

#products-list-mobile {
  display: none;
  color: rgba(255, 255, 255, 0.5);
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  padding: 5px;
}

#products-list-mobile li {
  padding-left: 10px;
}

.fade-in {
  opacity: 1;
  -webkit-animation-name: fadeInOpacity;
          animation-name: fadeInOpacity;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
  -webkit-animation-duration: 0.25s;
          animation-duration: 0.25s;
}

@-webkit-keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* BREADCRUMB */

ol.breadcrumb {
  margin: 0;
  background-color: rgb(14, 14, 14);
}

ol.breadcrumb li a {
  font-size: 11pt;
}

.pages nav ol.breadcrumb {
  border-radius: 0;
}

.breadcrumb-item {
  color: #dbdbdb;
}

.breadcrumb-item:hover {
  color: #dbdbdb;
  text-decoration: none;
}

.breadcrumb-item a.active {
  color: #777777;
}

.breadcrumb-item a.active:hover {
  color: #dbdbdb;
}

/* BANNER IMAGE */

.image-container {
  max-width: none;
}

.image-wrapper {
  position: relative;
  padding-bottom: 56.2%;
}

.image-wrapper img {
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

/* GENERAL PAGES */

.pages {
  min-height: 600px;
  margin-top: 186px;
}

/* HOME PAGE */

button.styles_closeButton__20ID4 {
  background-color: #dbdbdb;
  border-radius: 50%;
}

button.styles_closeButton__20ID4:focus {
  outline: none;
}

.home-products {
  margin: 0;
}

.home-products .left {
  padding: 10px 5px 5px 10px;
}
.home-products .right {
  padding: 10px 10px 5px 5px;
}

.home-products .bottom {
  padding: 5px 10px 10px 10px;
}

.home-products .prod img {
  width: 100%;
  height: auto;
}
.home-products .prod img:hover {
  cursor: pointer;
}

div.home-products {
  margin: 0;
  background-color: rgb(0, 0, 0);
}
.home-products .prod:hover a {
  text-decoration: none;
}
h5.home-prod-title {
  color: #dbdbdb;
}
p.home-prod-title {
  color: #dbdbdb;
}
p.home-prod-title:hover {
  text-decoration: underline;
}
.prod-text-title {
  width: 97%;
  top: 10%;
  position: absolute;
}

/* AZIENDA PAGE */

.parallax {
  /* The image used */

  /* Set a specific height */
  /*height: 500px;*/

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding-bottom: 40.4%;
  width: 100%;
  height: 100%;
}

.parallax2 {
  /* The image used */

  /* Set a specific height */
  /*height: 500px;*/

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding-bottom: 26.2%;
  width: 100%;
  height: 100%;
}

.azienda {
  padding: 50px 200px 50px 200px;
  text-align: justify;
  color: #efefef;
}

.azienda a {
  color: #e19dc4;
  font-weight: bolder;
}
.azienda a:hover {
  text-decoration: none;
  color: #e19dc4;
}

/* PRODOTTI PAGE */

.row div.prod-card {
  cursor: pointer;
  border: none;
}

.parallax-prod {
  /*height: 500px;*/

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding-bottom: 51.3%;
  width: 100%;
  height: 100%;
}

.card-body {
  background-color: #dbdbdb;
  color: #1b1b1b;
}

.card-text {
  text-align: justify;
}
.all-prod {
  margin-top: 0;
  margin-bottom: 0;
}
.all-prod .row {
  padding: 20px;
  margin: 0;
}
.all-prod a:hover {
  text-decoration: none;
}
.all-prod .col-lg-4 {
  padding: 10px;
}

.all-prod .col-lg-4 .prod-card {
  margin: 0;
}
.all-prod .card-img-top {
  border-radius: 0;
}

/* CONTATTI PAGE */

.contacts {
  color: #efefef;
}

.contacts .row {
  padding: 30px 30px 50px 30px;
  margin: 10px 10px 0 10px;
}

#map {
  position: relative;
  width: 100%;
  height: 100%;
  /* border: 4px solid #0f0f0f; */
}

.contacts-parallax {
  /* Set a specific height */
  /*height: 500px;*/

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding-bottom: 43.2%;
  width: 100%;
  height: 100%;
}

/* PRODUCT ITEMS PAGE */
.product-items .row {
  margin: 0;
  padding: 0;
}
.product-items .row a {
  margin: 0;
  padding: 0;
}
.product-items .row img {
  width: 100%;
  background-color: #1b1b1b;
  border-right: 1px solid #3d3d3d;
  padding: 0;
}
.product-items .row img:hover {
  opacity: 0.7;
}
.product-items .row a:hover {
  text-decoration: none;
}
.product-items .row p {
  color: #dbdbdb;
  text-decoration: none;
}

/* PRODUCT PAGE */

.product-page {
  color: #dbdbdb;
}

.image-gallery button:focus {
  outline: none;
}

.slideshow {
  padding: 0 20px 20px 20px;
}

.fullscreen .image-gallery-slide img {
  height: 100vh;
  width: auto;
  /* margin-left: 19%; */
}

div.image-gallery-image {
  text-align: center;
}

.form-submit {
  background-color: #0e0e0e;
  color: #dbdbdb;
  border-radius: 2px;
  padding: 5px;
}
.form-submit:hover {
  color: #000;
  background-color: #dbdbdb;
}

input.form-send-btn {
  background-color: #0c0c0c;
  color: #dbdbdb;
}
input.form-send-btn:hover {
  background-color: #dbdbdb;
  color: #0c0c0c;
}

/* FOOTER */

.footer {
  padding: 40px 40px 20px 40px;
  background-color: #0c0c0c;
}

.footer .row .col-lg-3 h6 {
  color: #fff;
  margin-bottom: 20px;
}

.footer .row .col-lg-3 {
  color: rgb(146, 146, 146);
  font-size: 10pt;
  line-height: 0.5em;
}

.footer ul li {
  display: inline;
  padding: 5px;
}

.footer ul {
  margin: 0;
  padding: 10px 0 0 10px;
  color: rgb(146, 146, 146);
}

.footer hr {
  border-top: 1px solid rgba(146, 146, 146, 0.507);
}

.footer .social a {
  color: rgb(146, 146, 146);
}

.footer .fab {
  font-size: x-large;
  padding: 5px;
}

.footer .policy {
  font-size: 10pt;
}

.footer br {
  display: none;
}

div.signup_footer form {
  background: #0c0c0c;
}

.signup_footer input.email {
  width: 100% !important;
}
#mc_embed_signup input.email:focus {
  outline: none;
}
#mc_embed_signup .button {
  background-color: #1b1b1b;
}

/* PAGE LOADER */

.spinner-logo {
  width: 100%;
  position: absolute;
  background-color: #000;
  height: 100%;
}

.logo-pulse {
  top: calc(50% - 50px);
  position: absolute;
  margin-left: calc(50% - 100px);
}

.pulse {
  -webkit-animation: pulse 2s infinite ease-in-out;
  animation: pulse 4s infinite ease-in-out;
}

.sweet-loading {
  padding-top: 12%;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(0.5);
    opacity: 0.3;
  }
  50% {
    -webkit-transform: scale(2);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0.5);
    opacity: 0.3;
  }
}

@keyframes pulse {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0.1;
  }
  50% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0.1;
  }
}

/* NOT FOUND PAGE */

.go-back {
  color: #dbdbdb;
}
.go-back:hover {
  color: #dbdbdb;
}

.not-found .kwDVRr {
  padding: 200px 50% 0 50%;
}

/* POLICY */
.footer-policy a {
  color: rgb(146, 146, 146);
}
.footer-policy a:hover {
  color: #fff;
  text-decoration: none;
}
.policy {
  color: #dbdbdb;
  padding: 30px;
}
.policy a {
  color: #dbdbdb;
}
.policy a:hover {
  color: #fff;
}

/* MOBILE CSS */

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #0c0c0c;
}

::-webkit-scrollbar-thumb {
  background: #2c2c2c;
}

::-webkit-scrollbar-thumb:hover {
  background: #3a3a3a;
}

@media all and (max-width: 991px) {
  .logo-pulse {
    margin-left: calc(50% - 55px);
  }
  .navbar:hover {
    background-color: transparent;
  }
  .navbar {
    background-image: -webkit-gradient(linear, left bottom, left top, from(#1b1b1b), to(#1b1b1b));
    background-image: linear-gradient(to top, #1b1b1b, #1b1b1b);
  }
  .collapse ul.navbar-nav {
    display: block;
    padding: 0 0 25px 0;
  }

  ul.navbar-nav {
    padding-bottom: 20px;
    padding-top: 10px;
    padding-right: 0;
  }

  ul.navbar-nav li {
    border-bottom: 1px solid #444444;
  }

  .arrow-menu-down {
    display: none;
  }

  nav.fixed-top {
    display: inline;
    display: initial;
  }
  .navbar-dark .navbar-toggler {
    float: right;
    margin: 12px -15px 12px 12px;
    padding: 16px;
  }
  .site-logo {
    width: 110px;
    height: 60px;
  }
  span.hamburger-inner,
  span.hamburger-inner::before,
  span.hamburger-inner::after {
    width: 30px;
    height: 3px;
    background-color: #fff;
  }
  .hamburger.is-active span.hamburger-inner,
  .hamburger.is-active span.hamburger-inner::before,
  .hamburger.is-active span.hamburger-inner::after {
    background-color: #fff;
  }
  .image-container {
    margin-top: 86px;
  }
  .azienda {
    padding: 50px;
    text-align: justify;
  }
  #products-list-mobile {
    display: block;
    padding: 10px;
  }

  #products-list-mobile li {
    border-bottom: 1px solid #444444;
    padding-bottom: 5px;
  }

  .product-navbar {
    padding-bottom: 0;
  }
  #products-list {
    display: none;
  }
  .pages {
    margin-top: 86px;
  }
  .home-products .col {
    width: 100%;
  }
  .home-products .left {
    padding: 10px 10px 5px 10px;
  }
  .home-products .right {
    padding: 5px 10px 5px 10px;
  }

  .home-products .bottom {
    padding: 60px 10px 10px 10px;
  }

  .parallax {
    background-attachment: scroll;
    position: relative;
    padding-bottom: 56.2%;
    background-size: cover;
    width: 100%;
    height: 100%;
    margin-top: 86px;
  }
  .contacts-parallax {
    margin-top: 86px;
    background-attachment: scroll;
    position: relative;
    padding-bottom: 56.2%;
    background-size: cover;
    width: 100%;
    height: 100%;
  }
  .card {
    margin-bottom: 20px;
  }
  .all-prod .col-lg-4 .first {
    padding: 20px 20px 10px 20px;
  }
  .all-prod .col-lg-4 .second {
    padding: 10px 20px 10px 20px;
  }
  .all-prod .col-lg-4 .third {
    padding: 10px 20px 20px 20px;
  }
  .parallax-prod {
    margin-top: 86px;

    background-attachment: scroll;
    position: relative;
    padding-bottom: 56.2%;
    background-size: cover;
    width: 100%;
    height: 100%;
  }
  .parallax2 {
    display: none;
  }

  .contacts .row {
    padding: 0;
    margin: 0;
  }
  .contacts .row .google-map {
    padding: 20px;
    margin: 0;
  }
  #map {
    border: none;
  }

  .pantofole .row img {
    border-right: 1px solid #2c2c2c;
  }

  ol.breadcrumb li a {
    font-size: 9pt;
  }

  .footer .fab {
    margin-right: 10px;
  }
  .footer .social {
    padding: 15px;
  }
  .footer br {
    display: block;
  }
  .sweet-loading {
    padding-top: 45%;
  }
  .slideshow {
    padding-bottom: 40px;
  }
  ul.navbar-nav li.lang-btn {
    position: relative;
    border-bottom: none;
    padding: 20px 0 0 0;
  }
  .fullscreen .image-gallery-slide img {
    height: auto;
    width: 100%;
    margin-left: 0;
  }
  .fullscreen {
    margin-top: 25%;
  }
}

